<template>
  <div>
    <client-only>
      <component :is="icon" :class="attrs.class" />
      <template #fallback />
    </client-only>
  </div>
</template>

<script setup lang="ts">
import AdjustmentHorizontal from '@heroicons/vue/24/outline/esm/AdjustmentsHorizontalIcon';
import ArrowPath from '@heroicons/vue/24/outline/esm/ArrowPathIcon';
import Bolt from '@heroicons/vue/24/outline/esm/BoltIcon';
import Bookmark from '@heroicons/vue/24/outline/esm/BookmarkIcon';
import Cog6Tooth from '@heroicons/vue/24/outline/esm/Cog6ToothIcon';
import GlobeAlt from '@heroicons/vue/24/outline/esm/GlobeAltIcon';
import QuestionMarkCircle from '@heroicons/vue/24/outline/esm/QuestionMarkCircleIcon';
import Envelope from '@heroicons/vue/24/outline/esm/EnvelopeIcon';
import MapPin from '@heroicons/vue/24/outline/esm/MapPinIcon';
import Squares2x2 from '@heroicons/vue/24/outline/esm/Squares2X2Icon';
import Star from '@heroicons/vue/24/outline/esm/StarIcon';
import User from '@heroicons/vue/24/outline/esm/UserIcon';
import HomeIcon from '@heroicons/vue/24/outline/esm/HomeIcon';
import Chat from '@heroicons/vue/24/outline/esm/ChatBubbleOvalLeftEllipsisIcon';
import Phone from '@heroicons/vue/24/outline/esm/PhoneIcon';
import Email from '@heroicons/vue/24/outline/esm/EnvelopeIcon';
import UserGroup from '@heroicons/vue/24/outline/esm/UserGroupIcon';
import Calendar from '@heroicons/vue/24/outline/esm/CalendarIcon';
import ClockIcon from '@heroicons/vue/24/outline/esm/ClockIcon';
import CanIcon from '~/public/icons/icon-can.svg?component';
import Sparkles from '@heroicons/vue/24/outline/esm/SparklesIcon';
import Truck from '@heroicons/vue/24/outline/esm/TruckIcon';
import ShoppingBag from '@heroicons/vue/24/outline/esm/ShoppingBagIcon';
import type { FunctionalComponent, HTMLAttributes, VNodeProps } from 'vue';

const props = defineProps<{
  iconName?: string,
}>();

const mapOfIconNames: { [name: string]: FunctionalComponent<HTMLAttributes & VNodeProps> } = {
  'adjustments-horizontal': AdjustmentHorizontal,
  'arrow-path': ArrowPath,
  'bolt': Bolt,
  'bookmark': Bookmark,
  'chat': Chat,
  'cog-6-tooth': Cog6Tooth,
  'globe-alt': GlobeAlt,
  'question-mark-circle': QuestionMarkCircle,
  'home': HomeIcon,
  'email': Email,
  'envelope': Envelope,
  'phone': Phone,
  'map-pin': MapPin,
  'snus-box': CanIcon,
  'squares-2x2': Squares2x2,
  'star': Star,
  'user': User,
  'user-group': UserGroup,
  'calendar': Calendar,
  'clock': ClockIcon,
  'sparkles': Sparkles,
  'truck': Truck,
  'shopping-bag': ShoppingBag,
};

const icon = mapOfIconNames[props.iconName];
  
const attrs = useAttrs();
</script>
